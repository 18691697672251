import * as Web from './web'
import {doWhenP, get} from './utils'
import {getRequestId, datalayerPush, datalayerEvent, config} from './Mapi'
import {error, getMapiUrl} from './Env'
import * as Cache from './cache'

function getQueryParams() {
  const qp = Web.parseQueryString()
  const params = [
    'kbid',
    'utm_channel',
    'utm_medium',
    'utm_campaign',
    'utm_source',
    'utm_term',
    'utm_content',
    'utm_name',
  ]
  const out = {}
  for (let i = 0, len = params.length; i < len; i++) {
    if (params[i] in qp) {
      out[params[i]] = qp[params[i]]
    }
  }
  return out
}

function getDefaultDataLayer() {
  const queryParams = getQueryParams()
  const requestId = getRequestId()

  const out = {
    postal_code: '',
    audience: 'Everyone',
    rotation_enabled: true,
    request_id: requestId,
    request_id_encoded: requestId,
    promo_code: '',
    kbid: '',
    marketing_channel: '',
    mobile: /Mobi/i.test(window.navigator.userAgent) ? 1 : 0,
    state: '',
    city: '',
    opt_test: '',
    opt_v: '',
    utm_channel: '',
    utm_medium: '',
    utm_campaign: '',
    utm_source: '',
    utm_term: '',
    utm_content: '',
    utm_name: '',
    aoa: [''],
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    promo_brand: '',
    visitor_id: '',
    buyflow: false,
    site_search: false,
    current_scroll_depth: 0,
    display_id: '',
    last_price_viewed: '',
    last_product_viewed: '',
    last_product_name: '',
    used_availability: true,
  }

  for (const i of Object.keys(queryParams)) {
    out[i] = queryParams[i]
  }

  return out
}

function getGeoData() {
  Web.getJSON(
    Web.constructUrl(getMapiUrl(), 'geoip'),
    [],
    res => {
      // Success
      datalayerPush({
        city: res.data.cityName,
        state: res.data.mostSpecificSubdivisionIsoCode,
        postal_code: res.data.cityPostalCode,
      })
      datalayerEvent({event: 'clDatalayerGeo'})
    },
    () => {
      // Fail
      error('Unable to retrieve geo ip data.')
    },
  )
}

function getMarketingData() {
  doWhenP(() => !!Cache.getCacheItem('requestData'), 500, 10000)
    .then(() => {
      const requestData = Cache.getCacheItem('requestData')
      const promoData = get(requestData, 'fullResponse.data.promo_data.data', {})
      const isp = get(requestData, 'fullResponse.datamaxmind.data.mm_isp', '')

      datalayerPush({
        promo_code: promoData.promo_code,
        promo_brand: promoData.promo_brand,
        marketing_channel: promoData.marketing_channel,
        rotation_enabled: promoData.conversion_tracking,
        isp,
      })

      datalayerEvent({event: 'clDatalayerPromoInfo'})
    })
    .catch(e => {
      if (e !== undefined) error(e)
    })
}

function getDataLayerValues() {
  // Search the values to populate in to the datalayer.
  getGeoData()
  getMarketingData()
}

// eslint-disable-next-line import/prefer-default-export
export function run() {
  doWhenP(() => getRequestId(), 500, 4000, true)
    .then(() => {
      // Only bootstrap the basic datalayer if configured to do so...
      if (config('bootstrapDataLayer')) {
        datalayerPush(getDefaultDataLayer())
        datalayerEvent({event: 'clDatalayerBasic'})
        datalayerEvent({event: 'clDatalayerProductInfo'})
      }

      // but regardless, we want the geo and promo code data
      getDataLayerValues()
    })
    .catch(e => {
      if (e !== undefined) error(e)
    })
}
