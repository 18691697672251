import {getRequestId, updateRequest} from '../Mapi'
import {doWhen} from '../utils'

/* callback added to make testing easier */
export function run(cb, timeout = 8000) {
  setTimeout(() => {
    doWhen(
      () => getRequestId(),
      () => {
        const reqId = getRequestId()
        const isBlocked = typeof window.ga !== 'function' || (window.ga.toString && window.ga.toString().length < 30)
        if (reqId) {
          updateRequest(reqId, {using_adblock: {using_adblock: isBlocked}})
        }

        if (cb) cb.call()
      },
      500,
      5000,
      true,
    )
  }, timeout)
}
