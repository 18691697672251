import * as Cache from './cache'
import * as web from './web'
import {config, get, filter, each, isEmpty, doWhen, doWhenP} from './utils'

import {getMapiUrl, debug, getFullLog, setEnv} from './Env'
import EventEmitter from './EventEmitter'
// eslint-disable-next-line import/no-cycle
import {RotationConfig} from './RotationConfig'
// eslint-disable-next-line import/no-cycle
import {lapi} from './additional/ErrorLog'

const maxUpdateSession = 4
let newSessionCallCount = 0

const {cookie, getFromDataLayer, getObjectFromDataLayer} = web

function getDataLayerRequestId() {
  // Fall back to the global cl_gtm if we can't find it in the data layer.
  const dlId = getFromDataLayer('requestID') || getFromDataLayer('request_id')
  const clGtmId = window.cl_gtm && window.cl_gtm.requestID

  return dlId || clGtmId
}

function setRequestIdCookie(requestId, ttlSeconds = 30 * 60) {
  web.cookie('clRequestId', requestId, ttlSeconds, '/', web.getStrippedDomain())
}

let mapiReadyFired = false

function fireMapiRequestIdReady(requestId) {
  if (mapiReadyFired) {
    return
  }
  mapiReadyFired = true
  const event = new CustomEvent('mapiRequestIdReady', {
    bubbles: true,
    detail: {requestId},
  })
  document.dispatchEvent(event)
}

function setDataLayerRequestId(requestId) {
  const prevDl = getDataLayerRequestId()
  if (requestId === prevDl) {
    fireMapiRequestIdReady(requestId)
    return
  }
  datalayerPush({request_id: requestId})
  fireMapiRequestIdReady(requestId)
}

function invalidate() {
  Cache.invalidateCache()
  setDataLayerRequestId('')
  setRequestIdCookie('', -3600)
  web.cookie('mapiJsPromo', '', -3600)
}

function handleExcessiveCall() {
  const log = [
    getFullLog(),
    Cache.getMapiCache(),
    // eslint-disable-next-line no-undef
    document.cookie,
    new Error().stack,
  ]

  lapi.log(JSON.stringify(log), 'ERROR')
}

/**
 * Update a particular request on MAPI with the provided extra data.
 * @param {string} requestId
 * @param {object} extraData
 * @param {function(): void} [cb]
 */
function updateRequest(requestId, extraData, cb) {
  if (!requestId) {
    return Promise.reject(new Error('updateRequest called with no requestId'))
  }

  const url = web.constructUrl(getMapiUrl(), ['cpr', 'external', 'request', requestId])
  return web
    .putJSON(url, {
      data: {
        request: extraData,
      },
    })
    .then(r => {
      cprRequest().handleResponse(r)
      if (cb) cb.call(null, r)
      return r
    })
}

async function updateRequestWhenAvailable(extraData) {
  return doWhenP(getRequestId).then(requestId => updateRequest(requestId, extraData))
}

function datalayerPush(obj) {
  window.dataLayer = window.dataLayer ?? []
  window.data_layer = window.data_layer ?? []

  // make sure we only push empty values if there is not already a value
  const toPush = {}
  for (const key of Object.keys(obj || {})) {
    const val = obj[key]
    const present = getFromDataLayer(key)
    if (!isEmpty(val) || isEmpty(present)) {
      toPush[key] = val
    }
  }
  if (Object.keys(toPush).length > 0) {
    window.dataLayer.push(toPush)
    // push to data_layer to support old code
    window.data_layer.push(toPush)
  }
}

/**
 * Push the event to the dataLayer
 * @param {{event: string|any}} obj
 */
function datalayerEvent(obj) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

// Store the customer ID in local storage, the MAPI cache, and a 3-year cookie
function setGuid(guid) {
  // 3 years
  const ttlSeconds = 60 * 60 * 24 * 365 * 3
  web.cookie('clGuid', guid, ttlSeconds)
  Cache.setCacheItem('clGuid', guid)

  if (getFromDataLayer('visitor_id') !== guid) {
    datalayerPush({visitor_id: guid})
    datalayerPush({clGuid: guid})
    datalayerEvent({event: 'clDatalayerGuid'})
  }
}

function cprRequest(promoCode, brand, domain, requestId) {
  const extraData = {}
  let tokens = []
  let allowRotation = true
  let allowLastResort = false
  let path = window.location.pathname
  let failure

  const rn = () => {}

  /**
   * @param {string[]} ts
   * @returns {rn}
   */
  rn.tokens = ts => {
    tokens = ts.filter(t => t).map(t => `${t}`.replace(/^\s+|\s+$/g, ''))
    return rn
  }

  rn.extra = (key, data) => {
    extraData[key] = data
    return rn
  }

  rn.catch = fn => {
    failure = fn
    return rn
  }

  rn.allowRotation = allow => {
    allowRotation = !!allow
    return rn
  }

  rn.path = p => {
    path = p
    return rn
  }

  rn.track = () => {
    // eslint-disable-next-line no-param-reassign
    requestId = requestId || getRequestId()
    return rn
  }

  rn.allowLastResortNumber = l => {
    allowLastResort = l
    return rn
  }

  rn.send = () => {
    const knownTokens = Cache.getCacheItem('knownTokens') || {}
    if (brand) {
      tokens.unshift(brand)
    }

    const anyExtra = Object.keys(extraData).length

    // only send an actual request if we don't have tokens that are being requested,
    // or if there is extra data to send
    const tokensToFetch = filter(tokens, t => typeof knownTokens[t] === 'undefined')

    // eslint-disable-next-line no-param-reassign
    requestId = requestId || getRequestId()

    if (requestId) {
      if (anyExtra) updateRequest(requestId, extraData)

      if (tokensToFetch.length && allowRotation) {
        return performTokenRequest(requestId, tokensToFetch).then(rn.handleResponse).catch(handleFailure)
      }
      return Promise.resolve({tokens: knownTokens, response: {}})
    }
    if (tokensToFetch.length && allowRotation) {
      return performInitialRequest(tokensToFetch).then(rn.handleResponse).catch(handleFailure)
    }

    return Promise.resolve({tokens: knownTokens, response: {}})
  }

  function performInitialRequest(newTokens) {
    const data = {
      data: {
        request: {
          brand,
          promo_code: promoCode,
          domain,
          path: path || window.location.pathname,
        },
        tokens: newTokens || [],
      },
    }

    each(extraData, (value, key) => {
      data.data.request[key] = value
    })

    const url = web.constructUrl(getMapiUrl(), ['cpr', 'external'])
    return web.postJSON(url, data)
  }

  function performTokenRequest(reqId, newTokens) {
    const data = {
      data: {
        domain,
        tokens: newTokens,
      },
    }

    const url = web.constructUrl(getMapiUrl(), ['cpr', 'external', 'request', reqId, 'tokens'])
    return web.postJSON(url, data)
  }

  function handleFailure(response, xhr) {
    debug('Failure in request to MAPI: ', response, xhr)
    if (failure) failure.call(response, xhr)
  }

  rn.handleResponse = response => {
    const reqId = get(response, 'data.request_id')
    const phoneResponses = get(response, 'data.phone.data')

    if (!reqId) {
      debug('No request ID returned', response)
      return Promise.resolve({})
    }

    setRequestId(reqId)
    const knownTokens = Cache.getCacheItem('knownTokens') || {}

    each(phoneResponses, p => {
      if (p.is_last_resort && !allowLastResort) return
      knownTokens[p.token] = `${p.promo_number}`
    })

    Cache.setCacheItem('requestData', {
      requestId: reqId,
      extraData,
      domain,
      promo: promoCode,
      fullResponse: response,
    })

    Cache.setCacheItem('knownTokens', knownTokens)

    const returnedPromo = get(response, 'data.promo_code')
    if (!getPromoCode() && returnedPromo) {
      datalayerPush({promo_code: returnedPromo})
      if (window.cl_gtm) window.cl_gtm.promoCode = returnedPromo
      Cache.setCacheItem('lastPromo', getPromoCode())
    }

    // store the customer id if returned
    const guidSet = get(response, 'data.customer_id.data.0.guid')
    if (guidSet) {
      setGuid(guidSet)
    }

    return Promise.resolve({tokens: knownTokens, response})
  }

  return rn
}

function updateForNewSession(oldRequestId, newRequestId) {
  newSessionCallCount += 1

  if (newSessionCallCount > maxUpdateSession) {
    debug('Excessive call count for updateForNewSession.')
    handleExcessiveCall()
    return
  }

  debug('Updating for new session')
  if (window.runMapiModules) {
    debug('running MAPI modules')
    window.runMapiModules(true)
  }

  if (window.piwikData && window.piwikData.updateVariables) {
    debug('running piwik update')
    window.piwikData.updateVariables()
  }

  if (oldRequestId && newRequestId) {
    debug('running request ID link')

    updateRequest(newRequestId, {
      linked_requests: {
        request_id: newRequestId,
        previous_request_id: oldRequestId,
      },
    })
  }
}

// Invalidate the current mapi-js session: clear the request ID and
function setRequestId(requestId) {
  if (!requestId) return

  const cached = Cache.getCacheItem('requestId')
  const previous = Cache.getCacheItem('previousRequestIds') || {}
  const incoming = requestId

  debug('cached:', cached)
  debug('previous:', previous)
  debug('incoming:', incoming)
  if (!cached) {
    Cache.setCacheItem('requestId', requestId)
    Cache.setCacheItem('previousRequestIds', previous)
    setDataLayerRequestId(requestId)
    setRequestIdCookie(requestId)
    return
  }

  if (cached && cached !== incoming) {
    if (previous && previous[incoming]) {
      debug('not clearing session, detected request ID cycle')
      return
    }

    previous[cached] = true

    debug('invalidating for mismatched request ID during set')
    invalidate()

    Cache.setCacheItem('requestId', requestId)
    Cache.setCacheItem('previousRequestIds', previous)
    setDataLayerRequestId(requestId)
    setRequestIdCookie(requestId)

    updateForNewSession(cached, incoming)
  } else {
    setDataLayerRequestId(requestId)
  }
}

/**
 * Look for a cached request ID. Also checks the dataLayer object, since CMS stores things there.
 * @returns string
 */
function getRequestId() {
  const cached = Cache.getCacheItem('requestId')
  let current = getDataLayerRequestId()

  if (cached && !current) {
    setDataLayerRequestId(cached)
    return cached
  }

  if (current && cached && cached !== current) {
    debug('invalidating for mismatched request ID')
    invalidate()
  }

  if (!current) {
    // only check the cookie if we don't have a current request ID at all
    current = web.cookie('clRequestId')
  }

  if (current && current !== cached) {
    setRequestId(current)
  }

  return current
}

function getPromoCode() {
  const promo =
    validOrNullify(web.parseUriQuery().kbid) ||
    debug('cl_gtm') ||
    (window.cl_gtm && validOrNullify(window.cl_gtm.promoCode)) ||
    debug('dl promoCode') ||
    validOrNullify(getFromDataLayer('promoCode')) ||
    debug('dl promo_code') ||
    validOrNullify(getFromDataLayer('promo_code')) ||
    debug('cookie promo') ||
    validOrNullify(web.cookie('promo')) ||
    debug('cookie mapiJsPromo') ||
    validOrNullify(web.cookie('mapiJsPromo')) ||
    debug('cache lastPromo') ||
    validOrNullify(Cache.getCacheItem('lastPromo')) ||
    debug('config defaultPromoCode') ||
    validOrNullify(config('defaultPromoCode'))

  if (!promo) return null
  return `${promo}`.replace(/^(\d+).*/, '$1')
}

function validOrNullify(promo) {
  let testPromo = promo
  if (typeof promo === 'string') {
    testPromo = Number(promo)
  }

  if (Number.isInteger(testPromo) && testPromo >= 0 && testPromo <= 9999999) {
    return promo
  }

  return null
}

function getPromoCodePromise(timeout = 5000) {
  return doWhenP(getPromoCode, 200, timeout, false)
}

/**
 * Yes, this is hilarious, but deliberate. getRequestId has the side effect of invalidating the
 * whole cache if it detects a change between the cached requestId and the one in the data layer.
 */
function checkForCacheInvalidation() {
  getRequestId()

  const promo = getPromoCode()
  const oldPromo = Cache.getCacheItem('lastPromo')

  if (!oldPromo) {
    Cache.setCacheItem('lastPromo', promo)
    return
  }

  if (promo && promo !== oldPromo) {
    debug('invalidating for mismatched promo', promo, oldPromo)
    invalidate()
    updateForNewSession()
    Cache.setCacheItem('lastPromo', `${promo}`)
  }
}

function getRequestData() {
  return get(Cache.getCacheItem('requestData'), 'fullResponse')
}

// Load the customer ID from local storage, falling back to the cookie
function getGuid() {
  return Cache.getCacheItem('clGuid') || web.cookie('clGuid')
}

let rotation
const events = new EventEmitter()
const addEventListener = (e, cb) => events.addListener(e, cb)
const removeEventListener = () => {}
// eslint-disable-next-line prefer-spread
const trigger = function t(...args) {
  events.emit(events, ...args)
}

function handlePromo() {
  const promo = getPromoCode()
  setPromoCookie(promo)
}

/**
 * At some point, this will actually be configured.
 *
 * @returns {Promise}
 */
function trackPageView() {
  const trackEnabled = config('trackEnabled', true)
  if (!trackEnabled) return Promise.resolve({})

  const mapiUrl = web.constructUrl(getMapiUrl(), ['cpr', 'external', 'track'])
  const promo = rotation.promo()

  const reqData = {
    data: {
      request: {
        request_id: rotation.requestId(),
        brand: rotation.brand(),
        promo_code: promo,
        domain: rotation.domain(),
        path: rotation.path(),
      },
    },
  }

  return web.postJSON(mapiUrl, reqData).then(cprRequest().handleResponse)
}

/**
 * Gets rotated numbers for the provided tokens.
 * @param {string[]} tokens
 * @param {RotationConfig|undefined} rotConfig
 * @return Promise<{tokens: {[token]: string}, response: any}>
 */
function fetchTokenNumbers(tokens, rotConfig = undefined) {
  return doWhenP(() => rotConfig || (window.MAPI && window.MAPI.Rotation)).then(() => {
    // eslint-disable-next-line no-param-reassign
    rotConfig = rotConfig || (window.MAPI && window.MAPI.Rotation)
    return cprRequest(rotConfig.promo(), rotConfig.brand(), rotConfig.domain(), rotConfig.requestId())
      .allowLastResortNumber(rotConfig.allowLastResort())
      .tokens(tokens)
      .send()
      .then(response => {
        events.emit('fetched-token-numbers', response ? response.tokens : [], this)
        return response
      })
  })
}

function setupLegacy() {
  if (window.mapiRegistered) {
    return
  }

  checkForCacheInvalidation()
  window.MAPI = window.MAPI || {}
  rotation = window.MAPI.Rotation || new RotationConfig(window.MAPI && window.MAPI.config)
  window.MAPI.Rotation = rotation

  // Make sure we give WP sites a chance to load their request IDs
  doWhen(
    () => window.mapiEvents && window.mapiEvents.on,
    () => {
      window.mapiEvents.on('mapi_heartbeat', (e, response) => {
        debug('setting request ID from wordpress')
        if (!response || !response.requestId) return
        setRequestId(response.requestId)
      })
    },
    100,
    2500,
  )

  // Wait until we have a request ID before tracking the page view. But only
  // wait a bit; just track the page if we don't get one
  doWhen(
    () => getRequestId() || (window.MAPI && window.MAPI.config),
    () => {
      trackPageView().then(() => {
        handlePromo()
      })
    },
    200,
    5000,
    true,
  )

  // Start looking for a request ID as soon as possible, trigger an event for the datalayer
  doWhenP(getRequestId, 100, 0).then(() => {
    setDataLayerRequestId(getRequestId())
    datalayerEvent({event: 'clDatalayerRequestId'})
  })

  window.mapiRegistered = true
}

/**
 *
 * @param rotConfig?: object
 * @returns {*|Promise<T>}
 */
function setup(rotConfig) {
  const {environment} = rotConfig || {}
  setEnv(environment)
  checkForCacheInvalidation()
  window.MAPI = window.MAPI || {}
  window.MAPI.config = rotConfig
  rotation = new RotationConfig(rotConfig)
  window.MAPI.Rotation = rotation

  return trackPageView().then(r => {
    handlePromo()
    return r
  })
}

/**
 *
 * @param rotConfig?: object
 * @returns {*|Promise<T>}
 */
function setupSigil(rotConfig) {
  const {environment} = rotConfig || {}
  setEnv(environment)
  checkForCacheInvalidation()
  window.MAPI = window.MAPI || {}
  window.MAPI.config = rotConfig
  rotation = new RotationConfig(rotConfig)
  window.MAPI.Rotation = rotation
  handlePromo()
}

function setPromoCookie(promo) {
  if (!promo) return

  if (promo !== web.cookie('promo')) {
    web.cookie('promo', promo, 60 * 60 * 24 * 30, '/', web.getStrippedDomain())
  }

  if (promo !== web.cookie('mapiJsPromo')) {
    web.cookie('mapiJsPromo', promo, 60 * 60 * 24 * 30, '/', web.getStrippedDomain())
  }
}

/**
 * Resolves to the current request ID when present
 */
function getRequestIdPromise(timeout = 5000) {
  return doWhenP(getRequestId, 200, timeout, false)
}

/**
 * Updates the current MAPI request with the given priority
 * @param {string|int} priority priority number
 * @returns {Promise<{}>} Resolves to the MAPI response
 */
function updateRequestPriority(priority) {
  return getRequestIdPromise().then(requestId => {
    const mapiUrl = web.constructUrl(getMapiUrl(), `/cpr/external/request/${requestId}/priority`)
    return web.postJSON(mapiUrl, {
      data: {
        request_id: requestId,
        priority,
      },
    })
  })
}

function replacePhoneNumbers() {
  return doWhen(() => window.MAPI && window.MAPI.replacePhoneNumbers).then(window.MAPI.replacePhoneNumbers)
}

function getFromCookies(name) {
  return cookie(name)
}

export {
  addEventListener,
  checkForCacheInvalidation,
  config,
  cprRequest,
  datalayerPush,
  datalayerEvent,
  debug,
  fetchTokenNumbers,
  getFromCookies,
  getFromDataLayer,
  getGuid,
  getMapiUrl,
  getObjectFromDataLayer,
  getPromoCode,
  getPromoCodePromise,
  getRequestData,
  getRequestId,
  getRequestIdPromise,
  removeEventListener,
  replacePhoneNumbers,
  setGuid,
  setup,
  setupLegacy,
  setupSigil,
  setDataLayerRequestId,
  setRequestId,
  trigger,
  updateRequest,
  updateRequestWhenAvailable,
  updateRequestPriority,
}
